import * as React   from "react";
import styled       from "@emotion/styled";

import { PromoSubscribe } from "./PromoSubscribe";
interface Props {}
const tabletBreakpoint = "900px";

const PromoButton:  React.FunctionComponent<Props> = props => {

    return (

          
                    <SubscribeContainer>
                        <PromoSubscribe 
                            newCustomersOnly={(
                                <div>
                                    <h1>ERROR</h1>
                                    <h2>This deal is for new members only.</h2>
                                </div>
                            )} />                    
                    </SubscribeContainer>
         


    );
};


const SubscribeContainer = styled.section`
    > div:first-of-type {
        margin-top: -28px;
        background-color:transparent!important;
        background:transparent!important;
    }
    > div > div:first-of-type {
        display:none;
        visibility: hidden;
    }
    > div > div:nth-of-type(2) {
        display:none;
        visibility: hidden;
    }
    > div > div:last-of-type {
        color: #1d222b;
    }
    button {
        &:hover {
            background: #1d222b!important;
        }
    }
    button > div {
        display: grid;
        grid-template-areas:
        ". . . . ."
        ". . price . ."
        ". total total total .";
        grid-template-columns: 1fr 1fr auto 1fr 1fr;
        grid-template-rows: 1fr auto 2fr;
        grid-gap: 4px;
text-align: center;
        @media (min-width: ${tabletBreakpoint}){
            // grid-template-columns: 1fr 1fr auto 1fr 1fr;
            // grid-template-areas:
            // ". . . . ."
            // ". . price . ."
            // ". total total total .";
        }
    }
    button >  div  > div:first-of-type {
        display: none!important;
        visibility hidden:
    }
    button >  div  > div:nth-of-type(2) {
margin: 0 auto;
    }    button >  div  > div:nth-of-type(3) {
        margin: 0 auto;
            }
    button > div > div:last-of-type {
        visibility: hidden;
        position: relative;

        display: none;
    }
    button > div > div:last-of-type:after {
        display: none;

        visibility: visible;
        
        content: "START NOW";
        
        top: 4px;
        left: 0;
        position: absolute;

        @media (min-width: ${tabletBreakpoint}){
            top: 0;
        }
    }
`;

export default PromoButton;