import * as React   from "react";
import styled       from "@emotion/styled";

import PromoBody        from "../../../components/landing/promo/shoprunner/PromoBody";
import FooterContainer  from "../../../components/landing/reusable/FooterContainer";

import "../../subscribe.scss";
import NotFoundPage from "../../404";

interface Props {}

const tabletBreakpoint = "900px";

const PromoSubscribePage:  React.FunctionComponent<Props> = props => {
    return <NotFoundPage />
    return (
        <LocalWrapper>
            <HeroImage>
                <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/promo_web/hero-home_sale.png"/>
                <GifImg src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/gif/home-workouts.gif"/>
            </HeroImage>
            <PromoBody/>
            <FooterContainer/>
        </LocalWrapper>
    );
};

const GifImg = styled.img`
    width: 29vw!important;
    max-width: 290px;
    margin-top: 5%!important;
`;

const HeroImage = styled.div`
    width: 100vw;
    overflow: hidden;
    height: calc(337.5/1200 * 100vw);

    position: absolute;

    img {
        height: auto;
        width: 100%;
        position: absolute;
        margin: auto;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
    @media (min-width: ${tabletBreakpoint}){
        img {
            height: calc(337.5/1200 * 100vw);
            width: 100%;
            height: auto;
        }
    }
`;

const LocalWrapper = styled.div`
    font-family: Barlow;

    color:#1d222b;
    background: #fff;

    width: 100vw;
    height: 100vh;
    overflow: scroll;

    position: fixed;
`;

export default PromoSubscribePage;