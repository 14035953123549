import * as React   from "react";
import styled       from "@emotion/styled";

interface Props {}
const screenBreakpoint = "700px";

const FooterContainer:  React.FunctionComponent<Props> = props => {

    return (
        <FooterContainerWide>
            <Container>
                <FooterText>
                    <DiscountTopText>
                        You've got the membership.
                        <br/>
                        Now it's time to get the gear.
                    </DiscountTopText>
                    <DiscountBottomText>
                        25% OFF FITPLAN STORE NOW
                    </DiscountBottomText>
                    <ButtonBox>
                    <a href="https://ftpln.com/homesaleweb">
                        <FooterButton>
                            SHOP HERE
                        </FooterButton>
                    </a>
                    <br/>
                    <br/>
                    </ButtonBox>
                </FooterText>
                <FooterGif>
                    <img src="https://images.fitplanapp.com/web/landing/social/apparel/gear.gif"/>
                </FooterGif>
            </Container>
        </FooterContainerWide>
    );
};

const FooterContainerWide = styled.div`
    padding-top: 10px;
    width: 100vw;
    background-color: #202122;
`;
const DiscountTopText = styled.div`
    font-size: 5vw;
    font-weight: 600;
    letter-spacing: .25vw;
    text-decoration: underline;

    margin-top: 5vw;

    @media (min-width: ${screenBreakpoint}) {
        font-size: 21px;

        letter-spacing: 2px;
        margin-top: 0;
    }
`;

const DiscountBottomText = styled.div`
    font-size: 5vw;
    letter-spacing: 2px;
    margin: 4vw auto;

    @media (min-width: ${screenBreakpoint}) {
        font-size: 24px;
        letter-spacing: 0;
        margin: 2% auto;
    }
`;

const Container = styled.div`
    text-align: center;
    width: 100vw;
    max-width: 600px;
    margin: 0 auto;
    background-color: #202122;

    display: grid;
    grid-gap: 0;
    grid-template-columns: 100vw;
    grid-template-rows: auto 100vw;
    grid-template-areas:
        "text"
        "giphy";

    @media (min-width: ${screenBreakpoint}){
        grid-template-columns: 70% 30%;
        grid-template-rows: auto;
        grid-template-areas:
            " text giphy ";
    }
    img {
        margin: 0 auto;
        height: 100%;
        width:100%;
        vertical-align: bottom;
    }
`;

const FooterGif = styled.div`
    grid-area: giphy;
    text-align: center;
    color: white;
`;

const FooterText = styled.div`
    grid-area: text;
    color: white;
    text-transform: uppercase;
    text-align: center;
    z-index: 10;
`;
const FooterButton = styled.button`
    color: white;
    font-size: 1em;
    font-weight: 600;
    font-stretch: normal;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    padding: 0.5em 1.5em;
    min-width: 5em;

    box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(79deg,#1144ff,#0076f9);
    border: none;
    outline: none;
    border-radius: 1em;

    :hover,
    :focus {
        background-image: linear-gradient(79deg, #1144ff, #1d222b);
        box-shadow: none;
    }

    @media (min-width: ${screenBreakpoint}) {
        padding: 0.75em 2em;
        border-radius: 1.5em;
    }
`;
const ButtonBox = styled.div`
    text-align: center;
`;
export default FooterContainer;