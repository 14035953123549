import * as React   from "react";
import styled       from "@emotion/styled";

import PromoButton from "./PromoButton";

interface Props {}
const tabletBreakpoint = "900px";

const PromoBody:  React.FunctionComponent<Props> = props => {

    return (
        <GradientContainer>
        <BreakDesktop/><BreakDesktop/><YellowTopSpacer></YellowTopSpacer>


                <YellowBox>
                    <br/>
                    <Title>
                        <img src="https://fitplan-marketing.s3-us-west-2.amazonaws.com/shoprunner_web/shoprunner-title.png"/>
                    </Title>
                    <Paragraph>
                        Welcome ShopRunner members! Fitplan is excited to exclusively{" "}
                        offer you 30% off our annual subscription. You get instant VIP access{" "}
                        to personal training plans from elite fitness trainers like Jen Selter,{" "}
                    Melissa Alcantara, Alex Rodriguez, Rob Gronkowski and more.
                    </Paragraph>
                    <RulesBox>
                        Offer valid via this web page only through June 18, 2020.{" "}
                        Offer valid for new clients only.{" "}
                        Offer is not redeemable in the Fitplan app.
                    </RulesBox>   
                    <PromoButton />
                </YellowBox>
                <BreakDesktop/><BreakDesktop/><YellowTopSpacer></YellowTopSpacer>
    </GradientContainer>
    );
};

const GradientContainer = styled.div`
    box-shadow: inset 0 7px 9px -7px rgba(128,130,177,0.4);
    position: relative;
    margin-top: calc(337.5/1200 * 100vw);

    @media (min-width: ${tabletBreakpoint}){

    }
`;
const YellowTopSpacer = styled.div`
    height: 1vw;
    @media (min-width: 900px) {
        display:none;
    }
`;

const BreakDesktop = styled.br`
    display: none;
    @media (min-width: ${tabletBreakpoint}){
        display: block;
    }
`;
    
const YellowBox = styled.div`
    background-color: #ffd900;
    border-radius: 15px;
    max-width: 600px;
    margin: 10px auto;

    @media (min-width: ${tabletBreakpoint}){
        margin: 10px auto;
    }
`;

const Title = styled.div`
    width: 100%;
    img {
        width: 90%;
        margin: 0 5%;
    }
`;
const Paragraph = styled.p`
    text-align: center;
    padding: 0 20px;
    max-width: 580px;
    margin: 10px auto;
    @media (min-width: ${tabletBreakpoint}){
        text-align: justify;
        padding: 0 60px;
    }
`;
const RulesBox = styled(Paragraph)`
    font-size: .75em;
`;
export default PromoBody;